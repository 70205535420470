import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Dialog, Transition } from "@headlessui/react";

// import { ShareSocial } from "react-share-social";

import WiAddress from "../context/WiAddress";
import PoiDisplay from "./PoiDisplay";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "./db";

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const MyPois = (props) => {
  const ctx = useContext(WiAddress);

  const pois = useLiveQuery(() => db.pois.toArray());

  const list_names = [
    {
      title: "Mes lieux",
      text1: "Wi",
      text2: "Adresse",
      text3: "Catégorie",
      text4:
        "Merci de renseigner quelques informations sur le lieu que vous souhaitez ajouter",
      text5: "Nom du lieu",
      category: ["Domicile", "Autre"],
      css_direction: "",
      css_direction2: "ltr",
      delete: "Supprimer",

      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
    {
      title: "إضافة الموقع",
      text1: "وي",
      text2: " عنوان",
      text3: "صنف الموقع",
      text4: "يرجى تقديم بعض المعلومات حول الموقع الذي ترغبون إضافته",
      text5: "إسم الموقع",
      category: ["منزل", "فئة أخرى"],
      css_direction: "flex-row-reverse",
      css_direction2: "rtl",
      delete: "حذف",
      css_1: " text-right",
      css_2: " rounded-full pr-4 ",
      css_3: " rounded-tl-md",
    },
    {
      title: "My places",
      text1: "Wi",
      text2: "Address",
      text3: "Category",
      text4: "Please provide some information about the place",
      text5: "Place name",
      category: ["Home", "Other"],
      css_direction: "",
      css_direction2: "ltr",
      delete: "Delete",
      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
    {
      title: "Imukan iw",
      text1: "Wi",
      text2: "Tansa",
      text3: "Taggayt",
      text4:
        "Ttxil-k.m efk-d kra n isallen ɣef imukan i tebɣiḍ ad ternuḍ",
      text5: "Isem n umkan",
      category: ["Axxam", "Nniḍen"],
      css_direction: "",
      css_direction2: "ltr",
      delete: "ekkes",
      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
  ];

  const [deletePois, setDeletePois] = useState([]);

  const deleteById = (id) => {
    setDeletePois((oldValues) => {
      return oldValues.filter((poi) => poi.id !== id);
    });
  };

  const pushById = (id) => {
    setDeletePois((oldValues) => {
      return [...oldValues, { id: id }];
    });
  };

  const [disabledDeletePois, setdisabledDeletePois] = useState(true);

  const onDelete = useCallback(() => {
    // close Dialog
    // setdisabledDeletePois(true);
    // props.closeDialog();

    if (deletePois.length > 0) {
      let idToBeDeleted = [];
      deletePois.map((poi) => {
        idToBeDeleted.push(poi.id);
        // console.log(poi.id);
      });

      db.pois
        .where("id")
        .anyOf(idToBeDeleted)
        .delete()
        .then(function (deleteCount) {
          // console.log("Deleted " + deleteCount + " pois");
          setDeletePois((oldValues) => {
            return [];
          });
        });
    }
  });

  useEffect(() => {
    if (deletePois.length > 0) {
      setdisabledDeletePois(false);
    } else {
      setdisabledDeletePois(true);
    }
  }, [deletePois]);

  return (
    <Transition appear show={props.showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={props.closeDialog}
        disableportal="true"
      >
        <div className="min-h-screen px-2 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xs  my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-gray-100 border-2  ">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 ">
                <div className="flex justify-between p-2 border-b-2 bg-green-700 text-white">
                  <div></div>
                  <div>{list_names[ctx.language].title}</div>
                  <div
                    className="h-6 w-6 border-2 rounded-md border-green-700 hover:border-white hover:cursor-pointer flex"
                    onClick={props.closeDialog}
                  >
                    <svg
                      className="m-auto"
                      width="16"
                      height="16"
                      viewBox="0 0 10 10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.728 4.738L9.475.99 8.485 0 4.738 3.748.99 0 0 .99l3.748 3.748L0 8.485l.99.99 3.748-3.747 3.747 3.747.99-.99-3.747-3.747z"
                        fill="#ffff"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Dialog.Title>
              <div className="flex flex-col    h-50   mx-auto ">
                <div className="flex justify-center m-2">
                  <button
                    type="button "
                    className="my-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-700 border border-transparent rounded-md enabled:hover:bg-red-400 enabled:focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-40 "
                    onClick={onDelete}
                    disabled={disabledDeletePois}
                  >
                    {list_names[ctx.language].delete}
                  </button>
                </div>
              </div>
              <div className="flex flex-col bg-gray-200">
                {pois?.map((poi) => {
                  let town = "";
                  switch (ctx.language) {
                    case 1:
                      town = poi.wiAddress.town_ar;
                      break;
                    case 3:
                      town = poi.wiAddress.town_kab;
                      break;
                    default:
                      town = poi.wiAddress.town;
                  }

                  return (
                    <PoiDisplay
                      title={poi.name}
                      category_number={poi.category}
                      //based on the selected language, provide the right name for the town
                      commune={town}
                      commune_orig={poi.wiAddress.town}
                      code={poi.wiAddress.code}
                      postal_code={poi.wiAddress.postal_code}
                      seprator={","}
                      pays={poi.wiAddress.country}
                      id={poi.id}
                      deleteById={deleteById}
                      pushById={pushById}
                      coordinates={poi.coordinates}
                      key={poi.id}
                      openDialog={props.openDialog}
                      openShareDialog={props.openShareDialog}
                      closeDialog={props.closeDialog}
                    ></PoiDisplay>
                  );
                })}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MyPois;
