import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import { Listbox, Dialog, Transition, RadioGroup } from "@headlessui/react";

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/solid";
// import { ShareSocial } from "react-share-social";

import WiAddress from "../context/WiAddress";

const style = {
  //   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 30px",
  //   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
};

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const PoiDisplay = (props) => {
  const ctx = useContext(WiAddress);

  const poiName = useRef();

  const list_names = [
    {
      title: "Mes lieux",
      text1: "Wi",
      text2: "Adresse",
      text3: "Catégorie",
      text4:
        "Merci de renseigner quelques informations sur le lieu que vous souhaitez ajouter",
      text5: "Nom du lieu",
      category: [
        "Domicile",
        "Site touristique",
        "Pharmacie",
        "Hôpital",
        "Commerce",
        "Restaurant",
        "Autre",
      ],
      css_direction: "",
      css_direction2: "ltr",

      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
    {
      title: "إضافة الموقع",
      text1: "وي",
      text2: " عنوان",
      text3: "صنف الموقع",
      text4: "يرجى تقديم بعض المعلومات حول الموقع الذي ترغبون إضافته",
      text5: "إسم الموقع",
      category: [
        "منزل",
        "موقع سياحي",
        "صيدلية",
        "مستشفى",
        "محل",
        "مطعم",
        "فئة أخرى",
      ],
      css_direction: "flex-row-reverse",
      css_direction2: "rtl",

      css_1: " text-right",
      css_2: " rounded-full pr-4 ",
      css_3: " rounded-tl-md",
    },
    {
      title: "My places",
      text1: "Wi",
      text2: "Address",
      text3: "Category",
      text4: "Please provide some information about the place",
      text5: "Place name",
      category: [
        "Home",
        "Tourist place",
        "Pharmacy",
        "Hospital",
        "Shop",
        "Restaurant",
        "Other",
      ],
      css_direction: "",
      css_direction2: "ltr",

      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
    {
      title: "Imukan iw",
      text1: "Wi",
      text2: "Tansa",
      text3: "Taggayt",
      text4: "Ttxil-k.m efk-d kra n isallen ɣef imukan i tebɣiḍ ad ternuḍ",
      text5: "Isem n umkan",
      category: [
        "Axxam",
        "Amkan n tirza",
        "Tafarmasit",
        "Ssbiṭar",
        "Taḥanutt",
        "Asečču",
        "Nniḍen",
      ],
      css_direction: "",
      css_direction2: "ltr",

      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
  ];

  const category = [
    {
      //home
      num: 0,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#714216"
        >
          <path d="M200-160v-366L88-440l-48-64 440-336 160 122v-82h120v174l160 122-48 64-112-86v366H560v-240H400v240H200Zm200-399h160q0-32-24-52.5T480-632q-32 0-56 20.5T400-559Z" />
        </svg>
      ),
    },

    {
      //Sightseeing
      num: 1,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#48752C"
        >
          <path d="M480-300q-42 0-71-29t-29-71q0-42 29-71t71-29h2q22 31 46.5 60t50.5 56q-5 36-33 60t-66 24ZM160-80q-33 0-56.5-23.5T80-160v-480q0-33 23.5-56.5T160-720h126l74-80h50q-5 19-7.5 39t-2.5 41q0 38 10 74t27 71q-60 14-98.5 62.5T300-400q0 75 52.5 127.5T480-220q54 0 97.5-28.5T643-323q20 19 39.5 36t37.5 32q42-35 82-71.5t78-75.5v242q0 33-23.5 56.5T800-80H160Zm560-280q121-103 180.5-191T960-714q0-113-72.5-179.5T720-960q-95 0-167.5 66.5T480-714q0 75 59.5 163T720-360Zm-74-240 28-91-74-59h91l29-90 29 90h91l-74 59 28 91-74-56-74 56Z" />
        </svg>
      ),
    },
    {
      //Pharmacies
      num: 2,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#15803d"
        >
          <path d="M437.69-280h84.62v-100h100v-84.61h-100v-100h-84.62v100h-100V-380h100v100ZM292.31-142.31q-29.83 0-51.07-21.24Q220-184.79 220-214.62V-630q0-29.83 21.24-51.07 21.24-21.24 51.07-21.24h375.38q29.83 0 51.07 21.24Q740-659.83 740-630v415.38q0 29.83-21.24 51.07-21.24 21.24-51.07 21.24H292.31Zm-43.08-615.38v-60h461.54v60H249.23Z" />
        </svg>
      ),
    },
    //hospital
    {
      num: 3,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#48752C"
        >
          <path d="M420-280h120v-100h100v-120H540v-100H420v100H320v120h100v100ZM160-120v-480l320-240 320 240v480H160Z" />
        </svg>
      ),
    },
    {
      //Shops
      num: 4,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#991b1b"
        >
          <path d="M168.46-730v-60h623.08v60H168.46ZM170-170v-240h-44.61v-60l43.07-200h623.08l43.07 200v60H790v240h-60v-240H550v240H170Zm60-60h260v-180H230v180Z" />
        </svg>
      ),
    },

    {
      //Restaurants
      num: 5,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#312e81"
        >
          <path d="M290-90v-363.69q-48.69-10.54-82.04-49.27-33.34-38.73-33.34-92.42V-870h59.99v274.62H290V-870h60v274.62h55.39V-870h59.99v274.62q0 53.69-33.34 92.42-33.35 38.73-82.04 49.27V-90h-60Zm384.61 0v-320H565.39v-270q0-74.92 48.11-129.96 48.11-55.04 121.11-59.27V-90h-60Z" />
        </svg>
      ),
    },
    {
      // Other
      num: 6,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#EAC452"
        >
          <path d="m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
        </svg>
      ),
    },
  ];

  // const [selected, setSelected] = useState(plans[0]);

  // const [selected2, setselected2] = useState(category[0]);

  // const [displayedCommune, setDisplayedCommune] = useState("");

  // const [seprator, setSeparator] = useState("");

  // const [disabledAdd, setdisabledAdd] = useState(true);

  // useEffect(() => {
  //   // console.log("here");
  //   if (poiName.current) {
  //     let inputName = poiName.current.value.trimStart();
  //     inputName = inputName.replaceAll("  ", " ");
  //     console.log(inputName);
  //     if (inputName.length > 0) {
  //       setdisabledAdd(false);
  //     } else {
  //       setdisabledAdd(true);
  //     }
  //   } else {
  //     setdisabledAdd(true);
  //   }
  // });

  // useEffect(() => {
  //   // setdisabledAdd(true);

  //   if (ctx.language == 1) {
  //     setSeparator("،");
  //     if (ctx.commune_ar !== "") {
  //       setDisplayedCommune(ctx.commune_ar);
  //     } else {
  //       setDisplayedCommune(ctx.commune);
  //     }
  //   } else if (ctx.language == 3) {
  //     setSeparator(",");
  //     if (ctx.commune_kab !== "") {
  //       setDisplayedCommune(ctx.commune_kab);
  //     } else {
  //       setDisplayedCommune(ctx.commune);
  //     }
  //   } else {
  //     setSeparator(",");
  //     setDisplayedCommune(ctx.commune);
  //   }
  // }, [ctx.language, ctx.commune, ctx.commune_ar, ctx.commune_kab]);

  const onChangeHandler = useCallback((event) => {
    if (event.target.checked) {
      props.pushById(props.id);
    } else {
      props.deleteById(props.id);
    }
  });

  const openNavDialog = useCallback(() => {
    ctx.setPoiWiAddressTown(props.commune);
    ctx.setPoiWiAddressTown_ar(props.commune);
    ctx.setPoiWiAddressTown_kab(props.commune);

    ctx.setPoiWiAddressPostalCode(props.postalCode);
    ctx.setPoiWiAddressCode(props.code);
    ctx.setPoiWiAddressCountry(props.pays);
    ctx.setPoiLongLat(props.coordinates);

    props.closeDialog();
    props.openDialog(0, true);
  });

  const openShareDialogHandler = useCallback(() => {
    ctx.setPoiWiAddressTown(props.commune_orig);
    ctx.setPoiWiAddressTown_ar(props.commune);
    ctx.setPoiWiAddressTown_kab(props.commune);

    ctx.setPoiWiAddressPostalCode(props.postal_code);
    ctx.setPoiWiAddressCode(props.code);
    ctx.setPoiWiAddressCountry(props.pays);
    ctx.setPoiLongLat(props.coordinates);

    // ctx.setShowPoiDialog(false);
    props.closeDialog();
    props.openShareDialog(true);
  });

  const share = (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   height="24px"
    //   viewBox="0 0 24 24"
    //   width="24px"
    //   fill="#000000"
    // >
    //   <path d="M0 0h24v24H0z" fill="none" />
    //   <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#FFFFFF"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
    </svg>
  );

  const wiaddress = (
    <>
      <div
        className={
          "flex justify-center w-full border-b-2 border-green-700" +
          list_names[ctx.language].css_direction
        }
      >
        <span className="font-bold text-sm px-2  rounded-t-lg  border-green-700 bg-green-700 text-white">
          <span className={" pr-1 " + "font-PermanentMarker "}>
            {list_names[ctx.language].text1}
          </span>
          {list_names[ctx.language].text2}
        </span>
      </div>

      <span
        className={
          "flex justify-center font-semibold p-1 " +
          list_names[ctx.language].css_direction
        }
        tabIndex="-1"
      >
        {props.commune} <span className="px-1"> {props.seprator}</span>
        <span className="font-bold  text-blue-800 px-1 " dir="ltr">
          {addSpace(props.code)}
        </span>
      </span>
      <span
        className="font-light text-sm text-gray-700 p-0.5 border-green-700 border-t-2 italic"
        tabIndex="-1"
      >
        {"("}
        {props.postal_code} {props.seprator + " "} {props.pays}
        {")"}
      </span>
    </>
  );

  const navigate = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="35px"
      viewBox="0 0 24 24"
      width="35px"
      fill="#FFFFFF"
      className="mx-auto"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z" />
    </svg>
  );

  let common_style_1 =
    "my-auto bg-cyan-600 rounded-md p-2 mx-2 h-10 hover:bg-cyan-500 hover:cursor-pointer hover:focus:shadow-md";
  let common_style_2 =
    " rounded-lg my-auto hover:bg-green-600 hover:cursor-pointer hover:focus:shadow-md";

  return (
    <div className="flex flex-col   h-50  mt-4 mx-2 mb-4">
      <div
        className={
          "flex   border-amber-600 " +
          list_names[ctx.language].css_direction +
          " " +
          list_names[ctx.language].css_direction2
        }
      >
        <div
          className={"flex w-10/12 " + list_names[ctx.language].css_direction}
        >
          <span>{category[props.category_number].svg}</span>
          <span className=" font-bold text-sm px-4 py-0.5  rounded-t-lg  border-amber-700 bg-amber-700 text-white ">
            {props.title}
          </span>
        </div>
        <div
          className={
            "flex  w-2/12 justify-end " + list_names[ctx.language].css_direction
          }
        >
          <div className="px-2">
            <input
              className="w-5 h-5"
              type="checkbox"
              onClick={onChangeHandler}
            />
          </div>
        </div>
      </div>

      {/* <div className="flex ">
                    <span className="font-semibold font-xl px-4 py-0.5 border-amber-600 bg-amber-600 border-b-2 text-white w-auto rounded-t-lg">
                      {"Maison familiale"}
                    </span>
                  </div> */}

      <div className="flex  bg-white rounded-b-lg border-t-2 border-amber-700 py-2">
        <div
          className="flex bg-white w-4/5 ml-1 my-auto rounded-lg flex flex-col  text-center  shadow-xl shadow-black/50"
          dir={list_names[ctx.language].css_direction}
        >
          {wiaddress}
        </div>

        <div
          className="flex flex-col "
          // onClick={openNavDialog}
        >
          <div
            className="flex items-center bg-green-700 border-green-700 border-2 rounded-md  mx-2 my-auto  h-10 hover:bg-green-600 hover:cursor-pointer hover:focus:shadow-md mb-1"
            onClick={openNavDialog}
          >
            <span className="flex  ">{navigate}</span>
          </div>
          <div
            className={` ${common_style_1} shadow-xl shadow-black/50 flex `}
            onClick={openShareDialogHandler}
          >
            {/* {!button_clicked && (
            <span className="motion-safe:animate-[ping_2s_ease-out_infinite] absolute inline-flex h-6 w-6 border-8 border-gray-200 rounded-full bg-sky-400 opacity-75"></span>
          )} */}
            <span className="flex">{share}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoiDisplay;
