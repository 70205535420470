import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import { Listbox, Dialog, Transition } from "@headlessui/react";

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/solid";
// import { ShareSocial } from "react-share-social";

import WiAddress from "../context/WiAddress";

import { db } from "./db";

const style = {
  //   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 30px",
  //   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
};

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const PoiBox = (props) => {
  const ctx = useContext(WiAddress);

  const poiName = useRef();

  const list_names = [
    {
      title: "Ajouter un lieu",
      text1: "Wi",
      text2: "Adresse",
      text3: "Catégorie",
      text4: "Merci de renseigner quelques informations sur le lieu",
      text5: "Nom du lieu",
      category: [
        "Domicile",
        "Site touristique",
        "Pharmacie",
        "Hôpital",
        "Commerce",
        "Restaurant",
        "Autre",
      ],
      css_direction: "",
      css_direction2: "ltr",
      add: "Ajouter",

      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
    {
      title: "إضافة الموقع",
      text1: "وي",
      text2: " عنوان",
      text3: "صنف الموقع",
      text4: "يرجى تقديم بعض المعلومات حول الموقع الذي ترغبون إضافته",
      text5: "إسم الموقع",
      category: [
        "منزل",
        "موقع سياحي",
        "صيدلية",
        "مستشفى",
        "محل",
        "مطعم",
        "فئة أخرى",
      ],
      css_direction: "flex-row-reverse",
      css_direction2: "rtl",
      add: "إضافة",
      css_1: " text-right",
      css_2: " rounded-full pr-4 ",
      css_3: " rounded-tl-md",
    },
    {
      title: "Add a place",
      text1: "Wi",
      text2: "Address",
      text3: "Category",
      text4: "Please provide some information about the place",
      text5: "Place name",
      category: [
        "Home",
        "Tourist place",
        "Pharmacy",
        "Hospital",
        "Shop",
        "Restaurant",
        "Other",
      ],
      css_direction: "",
      css_direction2: "ltr",
      add: "Add",
      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
    {
      title: "Rnu amkan",
      text1: "Wi",
      text2: "Tansa",
      text3: "Taggayt",
      text4: "Ttxil-k.m efk-d kra n isallen ɣef imukan i tebɣiḍ ad ternuḍ",
      text5: "Isem n umkan",
      category: [
        "Axxam",
        "Amkan n tirza",
        "Tafarmasit",
        "Ssbiṭar",
        "Taḥanutt",
        "Asečču",
        "Nniḍen",
      ],
      css_direction: "",
      css_direction2: "ltr",
      add: "Rnu",
      css_1: " text-left",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-tr-md",
    },
  ];

  const category = [
    {
      //home
      num: 0,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#714216"
        >
          <path d="M200-160v-366L88-440l-48-64 440-336 160 122v-82h120v174l160 122-48 64-112-86v366H560v-240H400v240H200Zm200-399h160q0-32-24-52.5T480-632q-32 0-56 20.5T400-559Z" />
        </svg>
      ),
    },

    {
      //Signtseeing
      num: 1,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#48752C"
        >
          <path d="M480-300q-42 0-71-29t-29-71q0-42 29-71t71-29h2q22 31 46.5 60t50.5 56q-5 36-33 60t-66 24ZM160-80q-33 0-56.5-23.5T80-160v-480q0-33 23.5-56.5T160-720h126l74-80h50q-5 19-7.5 39t-2.5 41q0 38 10 74t27 71q-60 14-98.5 62.5T300-400q0 75 52.5 127.5T480-220q54 0 97.5-28.5T643-323q20 19 39.5 36t37.5 32q42-35 82-71.5t78-75.5v242q0 33-23.5 56.5T800-80H160Zm560-280q121-103 180.5-191T960-714q0-113-72.5-179.5T720-960q-95 0-167.5 66.5T480-714q0 75 59.5 163T720-360Zm-74-240 28-91-74-59h91l29-90 29 90h91l-74 59 28 91-74-56-74 56Z" />
        </svg>
      ),
    },
    {
      //Pharmacies
      num: 2,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#15803d"
        >
          <path d="M437.69-280h84.62v-100h100v-84.61h-100v-100h-84.62v100h-100V-380h100v100ZM292.31-142.31q-29.83 0-51.07-21.24Q220-184.79 220-214.62V-630q0-29.83 21.24-51.07 21.24-21.24 51.07-21.24h375.38q29.83 0 51.07 21.24Q740-659.83 740-630v415.38q0 29.83-21.24 51.07-21.24 21.24-51.07 21.24H292.31Zm-43.08-615.38v-60h461.54v60H249.23Z" />
        </svg>
      ),
    },
    //hospital
    {
      num: 3,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#48752C"
        >
          <path d="M420-280h120v-100h100v-120H540v-100H420v100H320v120h100v100ZM160-120v-480l320-240 320 240v480H160Z" />
        </svg>
      ),
    },
    {
      //Shops
      num: 4,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#991b1b"
        >
          <path d="M168.46-730v-60h623.08v60H168.46ZM170-170v-240h-44.61v-60l43.07-200h623.08l43.07 200v60H790v240h-60v-240H550v240H170Zm60-60h260v-180H230v180Z" />
        </svg>
      ),
    },

    {
      //Restaurants
      num: 5,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#312e81"
        >
          <path d="M290-90v-363.69q-48.69-10.54-82.04-49.27-33.34-38.73-33.34-92.42V-870h59.99v274.62H290V-870h60v274.62h55.39V-870h59.99v274.62q0 53.69-33.34 92.42-33.35 38.73-82.04 49.27V-90h-60Zm384.61 0v-320H565.39v-270q0-74.92 48.11-129.96 48.11-55.04 121.11-59.27V-90h-60Z" />
        </svg>
      ),
    },
    {
      // Other
      num: 6,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#EAC452"
        >
          <path d="m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
        </svg>
      ),
    },
  ];
  const [selected, setSelected] = useState(category[0]);

  const [displayedCommune, setDisplayedCommune] = useState("");

  const [seprator, setSeparator] = useState("");

  const [disabledAdd, setdisabledAdd] = useState(true);

  const onAdd = useCallback(async () => {
    try {
      // Add the new POI
      // name, category, wiAddress, coordinates;
      let inputName = poiName.current.value.trimStart();
      inputName = inputName.replaceAll("  ", " ");

      const id = await db.pois.add({
        name: inputName,
        category: selected.num,
        coordinates: ctx.longlat,
        description: "",
        image: getImgByCategory(selected.num),
        phoneNumber: "",
        email: "",
        website: "",
        wiAddress: {
          town: ctx.commune,
          town_ar: ctx.commune_ar,
          town_kab: ctx.commune_kab,
          code: ctx.code,
          country: ctx.pays,
          postal_code: ctx.postal_code,
        },
      });

      // console.log(`POI added with id: ${id}`);

      setdisabledAdd(true);
      props.closeDialog();
    } catch (error) {
      console.log(`Failed to add POI: ${error}`);
    }
  });
  useEffect(() => {
    // console.log("here");
    if (poiName.current) {
      let inputName = poiName.current.value.trimStart();
      inputName = inputName.replaceAll("  ", " ");

      if (inputName.length > 0) {
        setdisabledAdd(false);
      } else {
        setdisabledAdd(true);
      }
    } else {
      setdisabledAdd(true);
    }
  });

  useEffect(() => {
    setdisabledAdd(true);

    if (ctx.language == 1) {
      setSeparator("،");
      if (ctx.commune_ar !== "") {
        setDisplayedCommune(ctx.commune_ar);
      } else {
        setDisplayedCommune(ctx.commune);
      }
    } else if (ctx.language == 3) {
      setSeparator(",");
      if (ctx.commune_kab !== "") {
        setDisplayedCommune(ctx.commune_kab);
      } else {
        setDisplayedCommune(ctx.commune);
      }
    } else {
      setSeparator(",");
      setDisplayedCommune(ctx.commune);
    }
  }, [ctx.language, ctx.commune, ctx.commune_ar, ctx.commune_kab]);

  const onChangeHandler = useCallback((event) => {
    let inputName = poiName.current.value.trimStart();
    inputName = inputName.replaceAll("  ", " ");
    if (inputName.length > 0) {
      setdisabledAdd(false);
    } else {
      setdisabledAdd(true);
    }
  });

  return (
    <Transition appear show={props.showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={props.closeDialog}
        disableportal="true"
      >
        <div className="min-h-screen px-2 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-10/12 max-w-xs  my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-gray-100 border-2 ">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 ">
                <div className="flex justify-between p-2 border-b-2 bg-green-700 text-white">
                  <div></div>
                  <div>{list_names[ctx.language].title}</div>
                  <div
                    className="h-6 w-6 border-2 rounded-md border-green-700 hover:border-white hover:cursor-pointer flex"
                    onClick={props.closeDialog}
                  >
                    <svg
                      className="m-auto"
                      width="16"
                      height="16"
                      viewBox="0 0 10 10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.728 4.738L9.475.99 8.485 0 4.738 3.748.99 0 0 .99l3.748 3.748L0 8.485l.99.99 3.748-3.747 3.747 3.747.99-.99-3.747-3.747z"
                        fill="#ffff"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Dialog.Title>
              <div className="flex flex-col    h-50   mx-auto ">
                <div
                  className={
                    "flex m-4 px-2 text-gray-500 text-sm " +
                    list_names[ctx.language].css_1
                  }
                >
                  {list_names[ctx.language].text4}
                </div>
                <div className={" flex justify-center mx-4 "}>
                  <input
                    className={
                      " w-full font-bold p-2 h-11 my-auto  border-2   border-gray-400 focus:outline-none focus:border-green-700 focus:ring-green-700 focus:ring-1 placeholder:italic placeholder:text-slate-400 placeholder:text-center placeholder:font-bold dark:opacity-90 " +
                      list_names[ctx.language].css_2 +
                      " " +
                      list_names[ctx.language].css_1
                    }
                    placeholder={list_names[ctx.language].text5}
                    spellCheck="false"
                    onChange={onChangeHandler}
                    ref={poiName}
                  />
                </div>

                <div
                  className={
                    "flex mx-4 mt-4 border-b-2 border-amber-600 " +
                    list_names[ctx.language].css_direction +
                    " " +
                    list_names[ctx.language].css_direction2
                  }
                >
                  <span className="font-bold text-sm px-2  rounded-t-lg  border-amber-600 bg-amber-600 text-white ">
                    {list_names[ctx.language].text3}
                  </span>
                </div>
                <div className={"flex justify-center mx-4 "}>
                  <Listbox value={selected} onChange={setSelected}>
                    <div className="relative  w-full">
                      <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block truncate">
                          <div
                            className={
                              "flex " +
                              list_names[ctx.language].css_direction +
                              " " +
                              list_names[ctx.language].css_direction2
                            }
                          >
                            <span className="mx-2"> {selected.svg}</span>{" "}
                            <span>
                              {list_names[ctx.language].category[selected.num]}
                            </span>
                          </div>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-32 w-full h-32  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {category.map((categ, categIdx) => (
                            <Listbox.Option
                              key={categIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? "bg-amber-100 text-amber-900"
                                    : "text-gray-900"
                                }`
                              }
                              value={categ}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    <div
                                      className={
                                        "flex " +
                                        list_names[ctx.language].css_direction +
                                        " " +
                                        list_names[ctx.language].css_direction2
                                      }
                                    >
                                      <span className="mx-2"> {categ.svg}</span>{" "}
                                      <span>
                                        {
                                          list_names[ctx.language].category[
                                            categ.num
                                          ]
                                        }
                                      </span>
                                    </div>
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>

                <div className="flex flex-col mt-4 mx-4">
                  {/* <span className="font-PermanentMarker pr-1">Wi</span> */}
                  <div
                    className={
                      "flex  w-full " + list_names[ctx.language].css_direction
                    }
                  >
                    <span className="font-bold text-sm px-2  rounded-t-lg  border-green-700 bg-green-700 text-white">
                      <span className={" pr-1 " + "font-PermanentMarker "}>
                        {list_names[ctx.language].text1}
                      </span>
                      {list_names[ctx.language].text2}
                    </span>
                  </div>
                  <div
                    className={
                      "flex flex-col border-green-700  border-2 rounded-b-md  " +
                      list_names[ctx.language].css_3
                    }
                  >
                    <span
                      className={
                        "flex justify-center font-semibold p-2 " +
                        list_names[ctx.language].css_direction
                      }
                      tabIndex="-1"
                    >
                      {displayedCommune}
                      <span className="px-1"> {seprator}</span>
                      <span className="font-bold  text-blue-800  " dir="ltr">
                        {addSpace(ctx.code)}
                      </span>
                    </span>
                    <span
                      className="font-light text-sm text-gray-700 p-0.5 border-gray-400 border-t-2 italic text-center"
                      tabIndex="-1"
                    >
                      {"("}
                      {ctx.postal_code} {seprator + " "} {ctx.pays}
                      {")"}
                    </span>
                  </div>
                </div>

                <div className="flex  justify-center mx-4 my-4 ">
                  <button
                    type="button "
                    className="my-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md enabled:hover:bg-blue-200 enabled:focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-40 "
                    onClick={onAdd}
                    disabled={disabledAdd}
                  >
                    {list_names[ctx.language].add}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

function getImgByCategory(catNum) {
  switch (catNum) {
    case 0:
      return "home.png";
    case 1:
      return "sightseeing.png";
    case 2:
      return "pharmacy.png";
    case 3:
      return "hospital.png";
    case 4:
      return "store.png";
    case 5:
      return "restaurant.png";
    case 6:
      return "star.png";
  }
  return "star.png";
}

export default PoiBox;
