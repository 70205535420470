import L from "leaflet";
import React, {
  useMemo,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import WiAddress from "../../context/WiAddress";

import { Marker, useMap, Popup } from "react-leaflet";

// import { IconRestaurant } from "./IconRestaurant";
// import { IconSightseeing } from "./IconSightseeing";
// import { IconStore } from "./IconStore";

// import { IconSchool } from "./IconSchool";

import { IconWiMapper } from "./IconWiMapper";
// import WiPopup from "./WiPopup";

import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../db";

const WiPoi = (props) => {
  const ctx = useContext(WiAddress);

  const localPois = useLiveQuery(() => db.pois.toArray());

  const openPoiDialog = useCallback((val) => {
    //console.log("open Share Dialog");
    ctx.setShowPoiDialog(true);
  });

  // const getIcon = useCallback((category) => {
  //   if (category === "sightseeing") {
  //     return IconSightseeing;
  //   } else if (category === "restaurant") {
  //     return IconRestaurant;
  //   } else if (category === "school") {
  //     return IconSchool;
  //   } else if (category === "store") {
  //     return IconStore;
  //   } else {
  //     return IconWiMapper;
  //   }
  // });

  return (
    <>
      {props.pois?.map((poi) => (
        <Marker
          key={poi.properties.name + poi.geometry.coordinates[1]}
          position={[poi.geometry.coordinates[1], poi.geometry.coordinates[0]]}
          icon={IconWiMapper(poi.properties.category)}
          eventHandlers={{
            click: (e) => {
              ctx.setPoiName(poi.properties.name);
              ctx.setPoiName_ar(poi.properties.name_ar);
              // ctx.setPoiName_kab(poi.properties.name_kab);
              if (poi.properties.name_kab) {
                ctx.setPoiName_kab(poi.properties.name_kab);
              } else {
                ctx.setPoiName_kab("");
              }

              ctx.setPoiCategory(poi.properties.category);
              ctx.setPoiImage(poi.properties.image);
              ctx.setPoiPhoneNumber(poi.properties.phoneNumber);
              ctx.setPoiEmail(poi.properties.email);
              ctx.setPoiWebsite(poi.properties.website);
              ctx.setPoiWiAddressTown(poi.properties.wiAddress.town);
              ctx.setPoiWiAddressTown_ar(poi.properties.wiAddress.town_ar);
              if (poi.properties.wiAddress.town_kab) {
                ctx.setPoiWiAddressTown_kab(poi.properties.wiAddress.town_kab);
              } else {
                ctx.setPoiWiAddressTown_kab("");
              }

              ctx.setPoiWiAddressPostalCode(
                poi.properties.wiAddress.postalCode
              );
              ctx.setPoiWiAddressCode(poi.properties.wiAddress.code);
              ctx.setPoiWiAddressCountry(poi.properties.wiAddress.country);
              ctx.setPoiLongLat({
                lat: poi.geometry.coordinates[1],
                long: poi.geometry.coordinates[0],
              });

              openPoiDialog(true);
              //console.log("marker clicked", e);
            },
          }}
        >
          {" "}
        </Marker>
      ))}

      {localPois?.map((poi) => (
        <Marker
          key={poi.name + poi.coordinates.lat}
          position={[poi.coordinates.lat, poi.coordinates.long]}
          icon={IconWiMapper(convertCategory(poi.category))}
          eventHandlers={{
            click: (e) => {
              ctx.setPoiName(poi.name);
              ctx.setPoiName_ar(poi.name);
              ctx.setPoiName_kab(poi.name);

              ctx.setPoiCategory(convertCategory(poi.category));
              ctx.setPoiImage(poi.image);
              ctx.setPoiPhoneNumber(poi.phoneNumber);
              ctx.setPoiEmail(poi.email);
              ctx.setPoiWebsite(poi.website);
              ctx.setPoiWiAddressTown(poi.wiAddress.town);
              ctx.setPoiWiAddressTown_ar(poi.wiAddress.town_ar);

              ctx.setPoiWiAddressTown_kab(poi.wiAddress.town_kab);

              ctx.setPoiWiAddressPostalCode(poi.wiAddress.postalCode);
              ctx.setPoiWiAddressCode(poi.wiAddress.code);
              ctx.setPoiWiAddressCountry(poi.wiAddress.country);
              ctx.setPoiLongLat({
                lat: poi.coordinates.lat,
                long: poi.coordinates.long,
              });

              openPoiDialog(true);
              //console.log("marker clicked", e);
            },
          }}
        >
          {" "}
        </Marker>
      ))}
    </>
  );
};

function convertCategory(catNum) {
  switch (catNum) {
    case 0:
      return "home";
    case 1:
      return "sightseeing";
    case 2:
      return "pharmacy";
    case 3:
      return "hospital";
    case 4:
      return "store";
    case 5:
      return "restaurant";
    case 6:
      return "star";
  }
  return "star";
}

export default WiPoi;
